import React, { useState, useEffect } from "react";
import styles from "./PatchnotesItem.module.css";
import PatchnotesItemContent from "./PatchnotesItemContent/PatchnotesItemContent";
import { NavLink } from "react-router-dom";
import {
  Home,
  Calendar,
  CircleCheck,
  Mail,
  Utensils,
  User,
  BookOpen,
  GraduationCap,
  Settings,
} from "lucide-react";

function PatchnotesItem({
  title,
  date,
  content_Accueil,
  content_EmploiDuTemps,
  content_Devoirs,
  content_Mails,
  content_Restauration,
  content_Paramètres,
  content_Scolarite,
  content_Promo,
  content_Parametres,
  content_Autre,
}) {
  return (
    <div className={styles.patchnotesItem}>
      <div className={styles.patchnotesItemTitle}>
        <h3>{title}</h3>
        <div className={styles.patchnotesItemDate}>
          <p>{date}</p>
        </div>
      </div>
      <div className={styles.patchnotesItemContainer}>
        {content_Accueil && content_Accueil.some((item) => item !== "") && (
          <PatchnotesItemContent
            title="Accueil"
            icon={<Home style={{ color: "var(--blue-900)" }} size="1.25rem" />}
            content={content_Accueil}
          />
        )}
        {content_EmploiDuTemps &&
          content_EmploiDuTemps.some((item) => item !== "") && (
            <PatchnotesItemContent
              title="Emploi du temps"
              icon={
                <Calendar style={{ color: "var(--blue-900)" }} size="1.25rem" />
              }
              content={content_EmploiDuTemps}
            />
          )}
        {content_Devoirs && content_Devoirs.some((item) => item !== "") && (
          <PatchnotesItemContent
            title="Devoirs"
            icon={
              <CircleCheck
                style={{ color: "var(--blue-900)" }}
                size="1.25rem"
              />
            }
            content={content_Devoirs}
          />
        )}
        {content_Mails && content_Mails.some((item) => item !== "") && (
          <PatchnotesItemContent
            title="Mails"
            icon={<Mail style={{ color: "var(--blue-900)" }} size="1.25rem" />}
            content={content_Mails}
          />
        )}
        {content_Restauration &&
          content_Restauration.some((item) => item !== "") && (
            <PatchnotesItemContent
              title="Restauration"
              icon={
                <Utensils style={{ color: "var(--blue-900)" }} size="1.25rem" />
              }
              content={content_Restauration}
            />
          )}
        {content_Paramètres &&
          content_Paramètres.some((item) => item !== "") && (
            <PatchnotesItemContent
              title="Paramètres"
              icon={
                <User style={{ color: "var(--blue-900)" }} size="1.25rem" />
              }
              content={content_Paramètres}
            />
          )}
        {content_Parametres &&
          content_Parametres.some((item) => item !== "") && (
            <PatchnotesItemContent
              title="Paramètres"
              icon={
                <Settings style={{ color: "var(--blue-900)" }} size="1.25rem" />
              }
              content={content_Parametres}
            />
          )}
        {content_Scolarite && content_Scolarite.some((item) => item !== "") && (
          <PatchnotesItemContent
            title="Scolarité"
            icon={
              <BookOpen style={{ color: "var(--blue-900)" }} size="1.25rem" />
            }
            content={content_Scolarite}
          />
        )}
        {content_Promo && content_Promo.some((item) => item !== "") && (
          <PatchnotesItemContent
            title="Promo"
            icon={
              <GraduationCap
                style={{ color: "var(--blue-900)" }}
                size="1.25rem"
              />
            }
            content={content_Promo}
          />
        )}
        {content_Autre && content_Autre.some((item) => item !== "") && (
          <PatchnotesItemContent title="Autre" content={content_Autre} />
        )}
      </div>
    </div>
  );
}

export default PatchnotesItem;
