import React from "react";
import styles from "./Video.module.css";
import { NavLink } from "react-router-dom";

function Video() {
  return (
    <div className={styles.video}>
      <div className={styles.videoContainer}>
        <div className={styles.videoText}>
          <div className={styles.videoTitle}>
            <p>
              🎬 Découvre Libellule en <span>vidéo</span>
            </p>
          </div>
          <div className={styles.videoDescription}>
            <p>
              Regardez cette vidéo pour explorer les fonctionnalités principales
              mettant en valeur le design épuré et moderne de Libellule.
            </p>
            <p>
              <span>Sans oublier</span> l’affichage de votre prochain cours et
              les devoirs des prochains jours directement sur la page d’accueil,
              notifications, personnalisation du profil, mode sombre, affichage
              du groupe de classe...
            </p>
          </div>
          <div className={styles.videoButtonsStore}>
            <a
              href="https://play.google.com/store/apps/details?id=com.libellule.libellule"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/img/play_store.webp" alt="" />
            </a>
            <a
              href="https://apps.apple.com/fr/app/libelluleapp/id6502843331"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/img/app_store.webp" alt="" />
            </a>
          </div>
        </div>
        <div className={styles.videoEmbed}>
          <iframe
            src="https://www.youtube.com/embed/30EjVuj1e7M?si=yLopYBkWl7z823mY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Video;
